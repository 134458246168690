<template>
  <el-dialog
    v-drag
    title="标为异常"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item label="原因" prop="exceptionReason">
          <el-input
            v-model="form.exceptionReason"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5}"
            resize="none"
            placeholder="请输入异常原因"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok('form')">确定</div>
    </span>
  </el-dialog>
</template>

<script>
import { markSurchargeException } from '@/api/fee'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    selectionItem: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        exceptionReason: ''
      },
      rules: {
        exceptionReason: [
          { required: true, message: '请输入异常原因', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    async ok(formName) {
      this.$refs[formName].validate(async(valid) => {
          if (valid) {
            try {
              const params = {
                ids: this.selectionItem.map(a => a.id),
                exceptionReason: this.form.exceptionReason
              }
              const result = await markSurchargeException(params)
              if (result.success) {
                this.$refs[formName].resetFields()
                this.$emit('ok')
              } else {
                this.$message.error(result.errorMessage)
              }
            } catch (error) {
              console.error(error)
            }
          } else {
            return false
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  .el-form-item {
    margin: 0;
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
