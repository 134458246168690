<template>
  <div class="view-contanier">
    <div class="opt">
      <div v-if="filterList.date" class="checkDate flex_c_c">
        查询时间：{{ filterList.date[0] + "~" + filterList.date[1] }}
      </div>
      <!-- <div class="export flex_c_c">
        <img src="@/assets/images/export.png" style="margin-right: 5px" />
        导出
      </div> -->
      <div class="search">
        <el-popover
          ref="popover"
          placement="top"
          class="searchPop"
          title="批量搜索"
          trigger="click"
          width="378"
        >
          <div class="close" @click="closeSearchPanel">
            <i class="el-icon-close"></i>
          </div>
          <base-muti-input
            ref="baseMutiInput"
            v-model="selectNo"
            :default="selectNo"
          ></base-muti-input>
          <div style="text-align: right; margin: 16px 0 0">
            <el-button size="small" @click="handleEmpty">清空</el-button>
            <el-button
              size="small"
              type="primary"
              @click="handleConfirm"
            >确定</el-button
            >
          </div>
        </el-popover>
        <el-input
          :value="searchVal"
          placeholder="请输入订单号/提单号/账单号"
          class="input-search"
          @focus="handleFocus"
        ></el-input>
        <div class="btn-search" @click="handleConfirm">
          <i class="el-icon-search"></i>
        </div>
      </div>
      <div v-permissson="'derive'" class="derive flex_c_c" @click="derive">
        <img src="@/assets/images/const/derive.png" alt="导出" />
        导出
      </div>
      <div v-permissson="'add'" class="add flex_c_c" @click="drawer = true">
        <img src="@/assets/images/const/add.png" />
        新增费用
      </div>
      <BaseFilter
        :isActive="isActive"
        :width="500"
        @sendFilter="sendFilter"
        @reset="reset"
      >
        <div class="filter">
          <el-form ref="form" :model="filterList" label-width="104px">
            <el-form-item label="费用日期">
              <el-date-picker
                v-model="filterList.date"
                style="width: 320px"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                unlink-panels
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="客户">
              <el-select
                v-model="filterList.companyId"
                filterable
                style="width: 320px"
                placeholder="请选择客户"
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="费用大类">
              <el-select
                v-model="filterList.feeTypeId"
                style="width: 320px"
                multiple
                placeholder="请选择费用大类"
              >
                <el-option
                  v-for="item in feeTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="费用细类">
              <el-select
                v-model="filterList.feeNameId"
                style="width: 320px"
                multiple
                placeholder="请选择费用细类"
              >
                <el-option
                  v-for="item in feeNameList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销售员">
              <el-select
                v-model="filterList.saleId"
                style="width: 320px;"
                placeholder="请选择销售员"
              >
                <el-option
                  v-for="item in userOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="费用状态">
              <el-radio-group v-model="filterList.status">
                <el-radio :label="''">所有</el-radio>
                <el-radio :label="0">正常</el-radio>
                <el-radio :label="1">异常</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item>
              <div slot="label" style="line-height: 20px;;">订单号/提单号<br>/账单号</div>
              <base-muti-input
                ref="baseMutiInput"
                v-model="filterList.selectNo"
                style="width: 320px"
              ></base-muti-input>
            </el-form-item> -->
          </el-form>
        </div>
      </BaseFilter>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pagerConfig.pageIndex"
        :pageSize="pagerConfig.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
        @sortChange="sortChange"
      >
        <template slot="feeName" slot-scope="row">
          <div
            v-if="row.scope.feeNameCode === 'RCF'"
            style="cursor: pointer; color: #2395f3"
            @click="adjust(row.scope)"
          >
            {{ row.scope.feeName }}
            <img src="@/assets/images/order/cut.png" alt="" />
          </div>
          <span v-else>{{ row.scope.feeName }}</span>
        </template>
        <template slot="status" slot-scope="row">
          <div
            v-if="row.scope.status === 0"
            class="staus flex_c_c"
            style="background: rgba(53, 186, 131, 0.1); color: #35ba83"
          >
            {{ row.scope.statusName }}
          </div>
          <div
            v-if="row.scope.status === 1"
            class="staus flex_c_c"
            style="background: rgba(254, 69, 61, 0.1); color: #fe453d"
          >
            <el-tooltip
              effect="dark"
              :content="row.scope.exceptionReason"
              placement="bottom"
            >
              <span>
                {{ row.scope.statusName }}
                <i class="el-icon-warning-outline"></i>
              </span>
            </el-tooltip>
          </div>
        </template>
        <template slot="isGenerateInvoice" slot-scope="row">
          <div :class="['invoice', `invoice${row.scope.isGenerateInvoice ? 1 : 0}`]">
            {{ row.scope.isGenerateInvoice ? '已出账' : '未出账' }}
          </div>
        </template>
        <template #custom-total>
          <div class="custom-total">
            <div class="custom-left">
              <div class="select">已选 {{ selectionItem.length }} 项</div>
              <div
                class="mark flex_c_c"
                :class="{ normal: selectionItem.length }"
                @click="handleNormal"
              >
                标为正常
              </div>
              <div
                class="mark flex_c_c"
                :class="{ anomaly: selectionItem.length }"
                @click="handleAnomaly"
              >
                标为异常
              </div>
            </div>
            <div class="total">
              费用总计：<span>{{ totalAmount }} {{ currency }}</span>
            </div>
          </div>
        </template>
      </BaseTable>
    </div>
    <MarkNnomalyDialog
      :dialogVisible="dialogVisible1"
      :selectionItem="selectionItem"
      @cancel="dialogVisible1 = false"
      @ok="ok1"
    ></MarkNnomalyDialog>
    <AdjustFeeDetailDialog
      :dialogVisible="dialogVisible2"
      :rowData="rowData"
      @cancel="dialogVisible2 = false"
    ></AdjustFeeDetailDialog>
    <AddExpenseDrawer
      :drawer="drawer"
      :companyDrapdown="companyDrapdown"
      @close="drawer = false"
      @ok="ok2"
    ></AddExpenseDrawer>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput')
import MarkNnomalyDialog from './components/MarkNnomalyDialog'
import AddExpenseDrawer from './components/AddExpenseDrawer'
import AdjustFeeDetailDialog from './components/AdjustFeeDetailDialog'
import {
  getSurchargePageList,
  getFeeTypeList,
  getFeeNamePageList,
  markSurchargeNormal
} from '@/api/fee'
import {
  getUserForType
} from '@/api/system'
import { getRecentMonthDate } from '@/utils/common'
import { derive } from '@/utils/common'
import { mapGetters } from 'vuex'
import { myMixins } from '@/mixins'
export default {
  name: 'FeeQuery',
  components: {
    BaseTable,
    BaseFilter,
    BaseMutiInput,
    MarkNnomalyDialog,
    AddExpenseDrawer,
    AdjustFeeDetailDialog
  },
  mixins: [myMixins],
  data() {
    return {
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      height: 0,
      isActive: false,
      tableData: {
        fileds: [
          {
            filed: 'companyId',
            label: '客户',
            width: '150',
            formatter: (row) => {
              const item = this.companyDrapdown.find(
                (a) => a.value === row.companyId
              )
              if (item) {
                return item.label
              } else {
                return ''
              }
            },
            fixed: 'left'
          },
          {
            filed: 'feeTypeName',
            label: '费用大类',
            width: '140'
          },
          {
            filed: 'feeName',
            label: '费用细类',
            width: '136',
            isCustom: true
          },
          {
            filed: 'trackingNumber',
            label: '订单号码',
            width: '200',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'referenceNumber',
            label: '参考号码',
            width: '250',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'blNo',
            label: '提单号',
            width: '200',
            isCopy: true,
            align: 'left'
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '200'
          },
          {
            filed: 'createdOn',
            label: '费用日期',
            width: '170',
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : '',
            isSort: true
          },
          {
            filed: 'qty',
            label: '数量',
            width: '110'
          },
          {
            filed: 'unitPrice',
            label: '单价',
            width: '136',
            formatter: (row) =>
              row.unitPrice ? `${row.unitPrice} ${row.currency}` : `0 ${row.currency}`
          },
          {
            filed: 'amount',
            label: '总金额',
            width: '136',
            formatter: (row) =>
              row.amount ? `${row.amount} ${row.currency}` : `0 ${row.currency}`
          },
          {
            filed: 'createType',
            label: '生成方式',
            width: '100',
            formatter: (row) => (row.createType ? '手动新增' : '系统生成')
          },
          {
            filed: 'isGenerateInvoice',
            label: '出账状态',
            width: '94',
            isCustom: true
          },
          {
            filed: 'status',
            label: '状态',
            width: '94',
            isCustom: true
          },
          {
            filed: 'afterAmount',
            label: '账户余额',
            width: '136',
            formatter: (row) =>
              row.afterAmount ? `${row.afterAmount} USD` : '0 USD'
          },
          {
            filed: 'id',
            label: '费用编号',
            width: '150'
          },
          {
            filed: 'reconInvoiceNumber',
            label: '账单号码',
            width: '170'
          }
        ],
        columns: [],
        total: 0,
        mutiCheck: true
      },
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      filterList: {
        date: '',
        feeTypeId: [],
        feeNameId: [],
        companyId: '',
        selectNo: [],
        status: '',
        saleId: ''
      },
      dialogVisible1: false,
      dialogVisible2: false,
      drawer: false,
      selectionItem: [],
      feeTypeOptions: [],
      feeTypeOptions2: [],
      userOptions: [],
      totalAmount: 0,
      currency: '',
      rowData: {},
      form: {
        exceptionReason: ''
      },
      orderObj: {},
      selectNo: '',
      searchVal: ''
    }
  },
  computed: {
    ...mapGetters(['companyDrapdown']),
    companyOptions() {
      return this.companyDrapdown
    },
    feeNameList() {
      const arr = this.feeTypeOptions2.filter(a => this.filterList.feeTypeId.indexOf(a.feeTypeId) > -1)
      return arr
    }
  },
  watch: {
    '$store.state.connect.connect': {
      handler: function(query) {
        if (query.menuCode) {
          const json = JSON.parse(query.queryJson)
          const param = {
            pageIndex: 1,
            pageSize: 20
          }
          this.isActive = true
          this.pagerConfig = param
          this.filterList.status = json.status
          if (json.date) {
            this.filterList.companyId = json.companyId
            this.filterList.date = json.date
          }
          this.getList(param)
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 93
    if (!this.$store.state.connect.connect.menuCode) {
     this.filterList.date = getRecentMonthDate(1)
     this.getList(this.pagerConfig)
    }
    this.getFeeTypeList()
    this.getUserList()
  },
  beforeDestroy() {
    this.$store.dispatch('setEmptyConnect')
  },
  methods: {
    getUserList() {
      getUserForType({
      }).then((res) => {
        if (res.success) {
          const arr = []
          res.data.filter(a => [102, 110, 111, 166].indexOf(a.type) > -1).map((item) => {
            arr.push({
              value: item.id,
              label: item.nameCH
            })
          })
          this.userOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    formatFilter() {
      return {
        feeTypeId: this.filterList.feeTypeId?.length
          ? this.filterList.feeTypeId
          : undefined,
        feeNameId: this.filterList.feeNameId?.length
          ? this.filterList.feeNameId
          : undefined,
        companyId:
          this.filterList.companyId !== ''
            ? this.filterList.companyId
            : undefined,
        status:
          this.filterList.status !== '' ? this.filterList.status : undefined,
        selectNo: this.searchVal ? this.searchVal.split(';') : undefined,
        startTime: this.filterList.date ? this.filterList.date[0] : undefined,
        endTime: this.filterList.date ? this.filterList.date[1] : undefined,
        saleId:
          this.filterList.saleId !== ''
            ? this.filterList.saleId
            : undefined
      }
    },
    handlePageChange(pager) {
      this.pagerConfig = pager
      this.getList(pager)
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    sortChange(orderObj) {
      if (orderObj.order) {
        this.orderObj = {
          sortField: orderObj.prop,
          sortBy: orderObj.order === 'asc' ? 0 : 1
        }
      } else {
        this.orderObj = {}
      }
      this.getList(this.pagerConfig)
    },
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.orderObj,
            ...this.formatFilter()
          }
        }
        const res = await getSurchargePageList(params)
        this.loading = false
        if (res.success) {
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
          this.currency = res.data.currency
          this.totalAmount = res.data.totalAmount.toFixed(2)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    sendFilter() {
      if (
        this.filterList.companyId !== '' ||
        this.filterList.status !== '' ||
        this.filterList.feeTypeId.length !== 0 ||
        this.filterList.feeNameId.length !== 0 ||
        this.filterList.selectNo.length !== 0 ||
        this.filterList.date !== '' ||
        this.filterList.saleId !== ''
      ) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.initList()
    },
    reset() {
      this.filterList = {
        companyId: '',
        status: '',
        feeTypeId: [],
        feeNameId: [],
        selectNo: [],
        saleId: '',
        date: getRecentMonthDate(1)
      }
      this.$refs.baseMutiInput.reset()
      this.selectNo = ''
      this.searchVal = ''
      this.isActive = false
    },
    initList() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.getList(param)
    },
    async getFeeTypeList() {
      try {
        const res = await getFeeTypeList()
        if (res.success) {
          const arr = []
          res.data &&
            res.data.map((item) => {
              arr.push({
                value: item.id,
                label: item.name
              })
            })
          this.feeTypeOptions = arr
          const promise = res.data.map(async(item) => {
            const params = {
              pageIndex: 1,
              pageSize: 1000,
              queryParam: {
                feeTypeId: item.id,
                search: '',
                isActived: '',
                isVisible: true
              }
            }
            let result
            try {
              result = await getFeeNamePageList(params)
            } catch (error) {
              console.error(error)
            }
            return new Promise((resolve) => {
              resolve(result)
            })
          })
          Promise.all(promise).then((res) => {
            const arr = []
            res.forEach((item) => {
              item.data.list.forEach((dataItem) => {
                arr.push({
                  ...dataItem,
                  value: dataItem.id,
                  label: dataItem.name
                })
              })
            })
            this.feeTypeOptions2 = arr
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    // 正常
    async handleNormal() {
      if (this.selectionItem.length) {
        const ids = this.selectionItem.map((a) => a.id)
        try {
          const result = await markSurchargeNormal(ids)
          if (result.success) {
            this.getList(this.pagerConfig)
          } else {
            this.$message.error(result.errorMessage)
          }
        } catch (error) {
          console.error(error)
        }
      }
    },
    // 异常
    async handleAnomaly() {
      if (this.selectionItem.length) {
        this.dialogVisible1 = true
      }
    },
    ok1() {
      this.dialogVisible1 = false
      this.getList(this.pagerConfig)
    },
    ok2() {
      this.drawer = false
      this.initList()
    },
    adjust(row) {
      this.rowData = row
      this.dialogVisible2 = true
    },
    derive() {
      const params = {
        ...this.formatFilter(),
        ...this.orderObj
      }
      derive('Finance/exportSurchargeList', 'post', params)
    },
    handleConfirm() {
      if (this.selectNo && this.selectNo !== '') {
        this.searchVal = this.selectNo.join(';')
        this.$refs.popover.doClose()
      }
      this.filterList.date = ''
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pagerConfig = param
      this.getList(param)
    },
    handleEmpty() {
      this.selectNo = ''
      this.searchVal = ''
      this.$refs.baseMutiInput.reset()
      this.$refs.popover.doClose()
      this.initList()
    },
    handleFocus() {
      this.$refs.popover.doShow()
    },
    closeSearchPanel() {
      this.$refs.popover.doClose()
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-contanier {
  width: calc(100% - 80px);
  margin: 0 auto;
}
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 400;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    & > div {
      height: 30px;
      box-sizing: border-box;
      margin-right: 5px;
      border-radius: 4px;
      cursor: pointer;
    }
    .search {
      position: relative;
      display: flex;
      width: 230px;
      margin-right: 5px;
      .searchPop {
        /deep/ .el-popover {
          z-index: 9999;
          right: 0;
        }
        .close {
          position: absolute;
          top: 6px;
          right: 10px;
          cursor: pointer;
          font-size: 20px;
        }
      }
      .input-search {
        width: 196px;
        height: 30px !important;
        /deep/ .el-input__inner {
          height: 30px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .btn-search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 30px;
        border: 1px solid #dbe4f2;
        border-left: 0;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        box-sizing: border-box;
      }
    }
    .derive {
      width: 80px;
      border: 1px solid #2395f3;
      color: #2395f3;
    }
    .add {
      color: #ffffff;
      width: 100px;
      background: #2395f3;
    }
  }
  .data {
    position: relative;
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .staus, .invoice {
      width: 60px;
      height: 26px;
      border-radius: 4px 4px 4px 4px;
      margin: 0 auto;
    }
    .invoice1 {
      background: rgba(53, 186, 131, .1);
      color: #35BA83;
    }
    .invoice0 {
      background: rgba(153, 153, 153, .1);
      color: rgba(153, 153, 153, 1);
    }
    .custom-total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      background: #f7f7f7;
      border: 1px solid #eeeeee;
      border-top: 0;
      padding-left: 17px;
      padding-right: 40px;
      box-sizing: border-box;
      .custom-left {
        display: flex;
        align-items: center;
      }
      .select {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        margin-right: 20px;
      }
      .total {
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        color: #333333;
        span {
          color: #fe453d;
          font-weight: bold;
        }
      }
      .mark {
        width: 80px;
        height: 30px;
        background: #ffffff;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #eeeeee;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        box-sizing: border-box;
        cursor: pointer;
        & + .mark {
          margin-left: 5px;
        }
        &.normal {
          border-color: #35ba83;
          color: #35ba83;
        }
        &.anomaly {
          border-color: #fe453d;
          color: #fe453d;
        }
      }
    }
  }
}
</style>
