<template>
  <el-dialog
    v-drag
    title="批量导入"
    :visible.sync="dialogVisible"
    width="1040px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="header">
        <div class="tip">
          <i class="el-icon-warning-outline"></i>提单必须是分单号
        </div>
        <div class="opt">
          <el-upload
            action="#"
            :show-file-list="false"
            :http-request="httpRequest"
          >
            <div class="select-file flex_c_c">
              <img src="@/assets/images/const/select.png" />
              选择文件
            </div>
          </el-upload>
          <div class="import flex_c_c" @click="download">
            <img src="@/assets/images/order/download.png" />
            下载导入模版
          </div>
        </div>
      </div>
      <div class="dialog-data">
        <BaseTable
          height="320"
          :tableData="tableData"
          :loading="loading"
          :isPager="false"
          :border="true"
        >
          <template slot="message" slot-scope="row">
            <span v-if="row.scope.message === '失败'" style="color: #fe453d">{{
              row.scope.message
            }}</span>
            <span v-else>{{ row.scope.message }}</span>
          </template>
        </BaseTable>
        <div class="total flex_c_c">
          <div class="entry">
            总条目：<span style="color: #35ba83">{{
              successArr.length + "(成功)"
            }}</span>
            <span style="color: #fe453d">{{ errorArr.length + "(失败)" }}</span>
          </div>
          <div class="amount">
            总金额：<span>{{ total }} USD</span>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="footers">
      <el-button class="cancel flex_c_c" :disabled="disabled" @click="cancel">取消</el-button>
      <div class="ok flex_c_c" @click="ok">确定</div>
    </span>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable.vue')
import { readSurchargeFile, addSurcharge } from '@/api/fee'
import config from '@/utils/config'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'message',
            label: '操作结果',
            width: '125',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'classificationName',
            label: '类型',
            width: '110',
            resizable: true
          },
          {
            filed: 'feeName',
            label: '费用项',
            width: '125',
            resizable: true
          },
          {
            filed: 'companyName',
            label: '客户',
            width: '125',
            resizable: true
          },
          {
            filed: 'currency',
            label: '币制',
            width: '80',
            resizable: true
          },
          {
            filed: 'referenceNumber',
            label: '单号',
            width: '163',
            resizable: true
          },
          {
            filed: 'qty',
            label: '数量',
            width: '90',
            resizable: true
          },
          {
            filed: 'unitPrice',
            label: '单价',
            width: '90',
            resizable: true
          },
          {
            filed: 'amount',
            label: '金额',
            width: '90',
            resizable: true
          }
        ],
        columns: []
      },
      successArr: [],
      errorArr: [],
      disabled: false,
      flag: true
    }
  },
  computed: {
    total() {
      const total = this.tableData.columns.reduce((prev, next) => {
        return prev + next.amount
      }, 0)
      return total
    }
  },
  methods: {
    cancel() {
      this.tableData.columns = []
      this.successArr = []
      this.errorArr = []
      this.$emit('cancel')
    },
    async ok() {
      if (!this.flag) {
        this.$message.warning('不可重复提交')
        return
      }
      this.disabled = true
      const promise = this.tableData.columns.map(async(a) => {
        let res
        try {
          res = await addSurcharge(a)
        } catch (error) {
          console.log(error)
        }
        return new Promise((resolve) => {
          resolve(res)
        })
      })
      // Promise.allSettled按顺序发送
      Promise.allSettled(promise).then((res) => {
        res.forEach((a, i) => {
          if (a.value.success) {
            this.successArr.push(a)
            this.tableData.columns[i].message = '成功'
          } else {
            this.errorArr.push(a)
            this.tableData.columns[i].message = '失败'
          }
        })
        if (!res.success) {
          this.disabled = false
          this.flag = false
          this.$message.error(res.errorMessage)
        }
      }).then(() => {
        this.disabled = false
        this.flag = false
      })
    },
    // 下载模板
    download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/file/downLoadTemplate?guid=6293364faaf0436b893b59d6086ae0c2`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    },
    // 选择文件
    async httpRequest({ file }) {
      const suffix = file.name.slice(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )
      if (suffix !== 'xlsx') {
        this.$message.warning('请上传xlsx文件')
        return
      }
      this.loading = true
      const formData = new FormData()
      formData.set('file', file)
      try {
        const result = await readSurchargeFile(formData)
        if (result.data.success) {
          this.loading = false
          this.tableData.columns = result.data.data.map((a) => {
            return {
              ...a,
              isImport: true
            }
          })
          this.$message.success(result.data.errorMessage)
        } else {
          this.loading = false
          this.$message.error(result.data.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    .tip {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #fe453d;
      align-self: flex-end;
    }
    .opt {
      display: flex;
      align-items: center;
      & div {
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        box-sizing: border-box;
        cursor: pointer;
      }
      .select-file {
        width: 100px;
        background: #35ba83;
        margin-right: 5px;
      }
      .import {
        width: 120px;
        border: 1px solid #35ba83;
        color: #35ba83;
      }
    }
  }
  .dialog-data {
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    /deep/ .fp-cell {
      border: 0;
    }
    .total {
      width: 100%;
      height: 40px;
      background: #f7f7f7;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      border-left: 1px solid #eeeeee;
      border-right: 1px solid #eeeeee;
      box-sizing: border-box;
      color: #333333;
    }
    .entry {
      & span + span {
        margin-left: 10px;
      }
    }
    .amount {
      margin-left: 20px;
      & span {
        font-weight: bold;
        color: #fe453d;
      }
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    border: 0;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
