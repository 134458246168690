<template>
  <el-dialog
    v-drag
    title="调整费用明细"
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="5%"
  >
    <div class="content">
      <div class="header">
        <div class="tab">
          <span
            :class="['tabItem', active === 0 ? 'active' : '']"
            @click="changeType(0)"
          >本次扣费</span>
          <span
            :class="['tabItem', active === 1 ? 'active' : '']"
            @click="changeType(1)"
          >历史扣费</span>
        </div>
        <div class="tip">
          <i class="el-icon-warning-outline"></i>单击任一行可显示明细列表
          <el-button style="margin-left: 10px;" type="primary" @click="download">导出本次费用明细</el-button>
        </div>
      </div>
      <div class="databox">
        <div class="data">
          <BaseTable
            height="280"
            :tableData="tableData1"
            :loading="loading1"
            :isPager="false"
            :border="true"
            :showSummary="true"
            :getSummaries="getSummaries1"
            @rowclick="getList2"
          >
          </BaseTable>
        </div>
        <div class="hr flex_c_c">
          明细列表<i class="el-icon-caret-bottom"></i>
        </div>
        <div class="data">
          <BaseTable
            height="280"
            :tableData="tableData2"
            :loading="loading2"
            :isPager="false"
            :border="true"
            :showSummary="true"
            :getSummaries="getSummaries2"
          >
          </BaseTable>
        </div>
      </div>
    </div>
  </el-dialog>
</template>GrossProfitQTDialog
<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { getRateAdjustment, getRateAdjustmentDetail } from '@/api/fee'
import { throttle, derive } from '@/utils/common'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      active: 0,
      loading1: false,
      loading2: false,
      tableData1: {
        fileds: [
          {
            filed: 'chargingOn',
            label: '费用日期',
            width: '200',
            formatter: (row) =>
              row.chargingOn ? row.chargingOn.slice(0, row.chargingOn.length - 3) : '',
            resizable: true
          },
          {
            filed: 'trackingNumber',
            label: '主单号',
            width: '200',
            resizable: true
          },
          {
            filed: 'feeName',
            label: '费用英文名称',
            width: '260',
            resizable: true
          },
          {
            filed: 'feeNameCN',
            label: '费用中文名称',
            width: '260',
            resizable: true
          },
          {
            filed: 'amount',
            label: '费用',
            width: '158',
            formatter: (row) =>
              row.amount ? `${row.amount + ' ' + row.currency}` : `0 ${row.currency}`,
            resizable: true
          }
        ],
        columns: []
      },
      tableData2: {
        fileds: [
          {
            filed: 'referenceNumber1',
            label: '客户单号',
            width: '200',
            resizable: true
          },
          {
            filed: 'trackingNumber',
            label: '子单号',
            width: '200',
            resizable: true
          },
          {
            filed: 'hawbPacage',
            label: '子单件数',
            width: '108',
            resizable: true
          },
          {
            filed: 'upsCreateWeight',
            label: '打单重',
            width: '131',
            formatter: (row) =>
              row.upsCreateWeight ? row.upsCreateWeight + ' IB' : '0 IB',
            resizable: true
          },
          {
            filed: 'upsChargeWeight',
            label: '计费重',
            width: '131',
            formatter: (row) =>
              row.upsChargeWeight ? row.upsChargeWeight + ' IB' : '0 IB',
            resizable: true
          },
          {
            filed: 'auditedDimension',
            label: '调整尺寸',
            width: '154',
            formatter: (row) =>
              row.upsChargeWeight ? row.upsChargeWeight + ' IN' : '0 IN',
            resizable: true
          },
          {
            filed: 'systemCost',
            label: '费用',
            width: '154',
            formatter: (row) =>
              row.systemCost ? `${row.systemCost + ' ' + row.currency}` : `0 ${row.currency}`,
            resizable: true
          }
        ],
        columns: [],
        showIndex: true,
        indexWidth: 70
      },
      thisRateAdjustments: [],
      historyRateAdjustments: []
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        this.getList1()
      }
    }
  },
  methods: {
    getSummaries1(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计(其他费用-系统运费)：'
          return
        } else if (index === columns.length - 1) {
          let sum = 0
          data.forEach(item => {
            if (item.feeNameCN === '系统运费') {
              sum -= +item.amount
            } else {
              sum += +item.amount
            }
          })
          sums[index] = `${sum.toFixed(2)} USD`
        }
      })
      return sums
    },
    getSummaries2(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计：'
          return
        } else if (index === 3) {
          sums[index] = data.reduce((prve, next) => {
            return prve + next.upsCreateWeight
          }, 0).toFixed(2)
          sums[index] += ' KG'
        } else if (index === 4) {
          sums[index] = data.reduce((prve, next) => {
            return prve + next.upsChargeWeight
          }, 0).toFixed(2)
          sums[index] += ' KG'
        } else if (index === columns.length - 1) {
          sums[index] = data.reduce((prve, next) => {
            return prve + next.systemCost
          }, 0).toFixed(2)
          sums[index] += ' USD'
        }
      })
      return sums
    },
    changeType(index) {
      this.active = index
      !index && (this.tableData1.columns = this.thisRateAdjustments)
      index && (this.tableData1.columns = this.historyRateAdjustments)
      this.tableData2.columns = []
    },
    async getList1() {
      this.loading1 = true
      try {
        const result = await getRateAdjustment({ surchargeID: this.rowData.id })
        if (result.success) {
          this.loading1 = false
          this.tableData1.columns = result.data.thisRateAdjustments
          this.thisRateAdjustments = result.data.thisRateAdjustments
          this.historyRateAdjustments = result.data.historyRateAdjustments
        } else {
          this.loading1 = false
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    getList2: throttle(async function(row) {
      this.loading2 = true
      try {
        const result = await getRateAdjustmentDetail(row)
        if (result.success) {
          this.loading2 = false
          this.tableData2.columns = result.data
        } else {
          this.loading2 = false
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }, 1500),
    cancel() {
      this.active = 0
      this.tableData1.columns = []
      this.tableData2.columns = []
      this.$emit('cancel')
    },
    download() {
      derive('Finance/exportRateAdjustmentDetail', 'get', { surchargeID: this.rowData.id })
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: calc(100% - 40px);
  margin: 0 auto;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .tabItem {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-left: 20px;
      cursor: pointer;
    }
    .active {
      color: #2395f3;
    }
    .tip {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      align-self: flex-end;
      color: #FE453D;
    }
  }
  .databox {
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .data {
    width: 100%;
    box-sizing: border-box;
    .total {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background: #FFFFFF;
      border-bottom: 1px solid #EEEEEE;
      box-sizing: border-box;
      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      }
      .cell1 {
        width: 200px;
        font-weight: bold;
        color: #666666;
        border-right: 1px solid #EEEEEE;
        box-sizing: border-box;
      }
      .cell2,
      .cell3,
      .cell4 {
        font-size: 14px;
        color: #FE453D;
      }
      .cell2 {
        width: 158px;
        margin-left: 720px;
      }
      .cell3 {
        width: 131px;
      }
      .cell4 {
        width: 154px;
      }
    }
  }
  .hr {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #35BA83;
    border-left: 1px solid #EEEEEE;
    border-right: 1px solid #EEEEEE;
    box-sizing: border-box;
    i {
      margin-left: 5px;
    }
  }
}

/deep/ .el-table__footer-wrapper tbody {
  & td.el-table__cell {
    background: transparent;
    color: #FE453D;
  }
  & td:first-child.el-table__cell {
    color: #666666;
    font-weight: bold;
  }
  & td {
    border-right: 0;
  }
}
</style>
