var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c(
        "div",
        { staticClass: "opt" },
        [
          _vm.filterList.date
            ? _c("div", { staticClass: "checkDate flex_c_c" }, [
                _vm._v(
                  " 查询时间：" +
                    _vm._s(
                      _vm.filterList.date[0] + "~" + _vm.filterList.date[1]
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "search" },
            [
              _c(
                "el-popover",
                {
                  ref: "popover",
                  staticClass: "searchPop",
                  attrs: {
                    placement: "top",
                    title: "批量搜索",
                    trigger: "click",
                    width: "378",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "close",
                      on: { click: _vm.closeSearchPanel },
                    },
                    [_c("i", { staticClass: "el-icon-close" })]
                  ),
                  _c("base-muti-input", {
                    ref: "baseMutiInput",
                    attrs: { default: _vm.selectNo },
                    model: {
                      value: _vm.selectNo,
                      callback: function ($$v) {
                        _vm.selectNo = $$v
                      },
                      expression: "selectNo",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "right",
                        margin: "16px 0 0",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: { click: _vm.handleEmpty },
                        },
                        [_vm._v("清空")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.handleConfirm },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-input", {
                staticClass: "input-search",
                attrs: {
                  value: _vm.searchVal,
                  placeholder: "请输入订单号/提单号/账单号",
                },
                on: { focus: _vm.handleFocus },
              }),
              _c(
                "div",
                { staticClass: "btn-search", on: { click: _vm.handleConfirm } },
                [_c("i", { staticClass: "el-icon-search" })]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "derive",
                  expression: "'derive'",
                },
              ],
              staticClass: "derive flex_c_c",
              on: { click: _vm.derive },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/const/derive.png"),
                  alt: "导出",
                },
              }),
              _vm._v(" 导出 "),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "add",
                  expression: "'add'",
                },
              ],
              staticClass: "add flex_c_c",
              on: {
                click: function ($event) {
                  _vm.drawer = true
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/images/const/add.png") },
              }),
              _vm._v(" 新增费用 "),
            ]
          ),
          _c(
            "BaseFilter",
            {
              attrs: { isActive: _vm.isActive, width: 500 },
              on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
            },
            [
              _c(
                "div",
                { staticClass: "filter" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.filterList, "label-width": "104px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "费用日期" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              type: "daterange",
                              "range-separator": "-",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                              "unlink-panels": "",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.filterList.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterList, "date", $$v)
                              },
                              expression: "filterList.date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择客户",
                              },
                              model: {
                                value: _vm.filterList.companyId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "companyId", $$v)
                                },
                                expression: "filterList.companyId",
                              },
                            },
                            _vm._l(_vm.companyOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "费用大类" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: {
                                multiple: "",
                                placeholder: "请选择费用大类",
                              },
                              model: {
                                value: _vm.filterList.feeTypeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "feeTypeId", $$v)
                                },
                                expression: "filterList.feeTypeId",
                              },
                            },
                            _vm._l(_vm.feeTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "费用细类" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: {
                                multiple: "",
                                placeholder: "请选择费用细类",
                              },
                              model: {
                                value: _vm.filterList.feeNameId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "feeNameId", $$v)
                                },
                                expression: "filterList.feeNameId",
                              },
                            },
                            _vm._l(_vm.feeNameList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "销售员" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择销售员" },
                              model: {
                                value: _vm.filterList.saleId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "saleId", $$v)
                                },
                                expression: "filterList.saleId",
                              },
                            },
                            _vm._l(_vm.userOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "费用状态" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.filterList.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "status", $$v)
                                },
                                expression: "filterList.status",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "" } }, [
                                _vm._v("所有"),
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("正常"),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("异常"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pagerConfig.pageIndex,
                  pageSize: _vm.pagerConfig.pageSize,
                  loading: _vm.loading,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  handleSelectionChange: _vm.handleSelectionChange,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "feeName",
                      fn: function (row) {
                        return [
                          row.scope.feeNameCode === "RCF"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#2395f3",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.adjust(row.scope)
                                    },
                                  },
                                },
                                [
                                  _vm._v(" " + _vm._s(row.scope.feeName) + " "),
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/order/cut.png"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _c("span", [_vm._v(_vm._s(row.scope.feeName))]),
                        ]
                      },
                    },
                    {
                      key: "status",
                      fn: function (row) {
                        return [
                          row.scope.status === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "staus flex_c_c",
                                  staticStyle: {
                                    background: "rgba(53, 186, 131, 0.1)",
                                    color: "#35ba83",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(row.scope.statusName) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          row.scope.status === 1
                            ? _c(
                                "div",
                                {
                                  staticClass: "staus flex_c_c",
                                  staticStyle: {
                                    background: "rgba(254, 69, 61, 0.1)",
                                    color: "#fe453d",
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content: row.scope.exceptionReason,
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.scope.statusName) +
                                            " "
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-warning-outline",
                                        }),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "isGenerateInvoice",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            {
                              class: [
                                "invoice",
                                `invoice${row.scope.isGenerateInvoice ? 1 : 0}`,
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.scope.isGenerateInvoice
                                      ? "已出账"
                                      : "未出账"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "custom-total",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "custom-total" }, [
                            _c("div", { staticClass: "custom-left" }, [
                              _c("div", { staticClass: "select" }, [
                                _vm._v(
                                  "已选 " +
                                    _vm._s(_vm.selectionItem.length) +
                                    " 项"
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "mark flex_c_c",
                                  class: { normal: _vm.selectionItem.length },
                                  on: { click: _vm.handleNormal },
                                },
                                [_vm._v(" 标为正常 ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "mark flex_c_c",
                                  class: { anomaly: _vm.selectionItem.length },
                                  on: { click: _vm.handleAnomaly },
                                },
                                [_vm._v(" 标为异常 ")]
                              ),
                            ]),
                            _c("div", { staticClass: "total" }, [
                              _vm._v(" 费用总计："),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.totalAmount) +
                                    " " +
                                    _vm._s(_vm.currency)
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2724609538
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("MarkNnomalyDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible1,
          selectionItem: _vm.selectionItem,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
          ok: _vm.ok1,
        },
      }),
      _c("AdjustFeeDetailDialog", {
        attrs: { dialogVisible: _vm.dialogVisible2, rowData: _vm.rowData },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible2 = false
          },
        },
      }),
      _c("AddExpenseDrawer", {
        attrs: { drawer: _vm.drawer, companyDrapdown: _vm.companyDrapdown },
        on: {
          close: function ($event) {
            _vm.drawer = false
          },
          ok: _vm.ok2,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }