var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "新增费用", size: 600 },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "view" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "选择类型", prop: "classification" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { input: _vm.onInput },
                              model: {
                                value: _vm.form.classification,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "classification", $$v)
                                },
                                expression: "form.classification",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("订单费用"),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("提单费用"),
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("充值费用"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "费用项", prop: "feeNameId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请输入内容",
                                filterable: "",
                              },
                              model: {
                                value: _vm.form.feeNameId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "feeNameId", $$v)
                                },
                                expression: "form.feeNameId",
                              },
                            },
                            _vm._l(_vm.feeOptions, function (a) {
                              return _c("el-option", {
                                key: a.value,
                                attrs: { label: a.label, value: a.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "referenceNumber",
                            rules: [
                              {
                                required: _vm.form.classification !== 2,
                                validator: _vm.validateNumber,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [
                              _vm._v("单号"),
                              !_vm.form.classification
                                ? _c("span", [
                                    _vm._v("(订单单号时效需为90天内的)"),
                                  ])
                                : _vm.form.classification === 1
                                ? _c("span", [
                                    _vm._v(
                                      "(输入提单/柜号；如有多条分单必须选中一条)"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                "popper-class": "fee-tooltip",
                                manual: true,
                                effect: "dark",
                                placement: "bottom-end",
                              },
                              model: {
                                value: _vm.showTip,
                                callback: function ($$v) {
                                  _vm.showTip = $$v
                                },
                                expression: "showTip",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.referenceNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "referenceNumber", $$v)
                                  },
                                  expression: "form.referenceNumber",
                                },
                              }),
                              _c("template", { slot: "content" }, [
                                _c(
                                  "div",
                                  { staticClass: "data" },
                                  [
                                    _c("BaseTable", {
                                      attrs: {
                                        height: "240",
                                        tableData: _vm.tableData,
                                        loading: _vm.loading,
                                        isPager: false,
                                      },
                                      on: { handlerRadio: _vm.handlerRadio },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "footer flex_c_c" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "cancel flex_c_c",
                                      on: {
                                        click: function ($event) {
                                          _vm.showTip = false
                                        },
                                      },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "confirm flex_c_c",
                                      on: { click: _vm.confirm },
                                    },
                                    [_vm._v("确定")]
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客户",
                            prop:
                              _vm.form.classification === 2
                                ? "companyId"
                                : "companyName",
                          },
                        },
                        [
                          _vm.form.classification !== 2
                            ? _c("el-input", {
                                staticStyle: { width: "270px" },
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.companyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "companyName", $$v)
                                  },
                                  expression: "form.companyName",
                                },
                              })
                            : _c(
                                "el-select",
                                {
                                  staticStyle: { width: "270px" },
                                  attrs: { placeholder: "请选择客户" },
                                  on: { change: _vm.companyChange },
                                  model: {
                                    value: _vm.form.companyId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "companyId", $$v)
                                    },
                                    expression: "form.companyId",
                                  },
                                },
                                _vm._l(_vm.companyDrapdown, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "币制", prop: "currency" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "270px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.currency,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "currency", $$v)
                              },
                              expression: "form.currency",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数量", prop: "qty" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "270px" },
                            attrs: { placeholder: "请输入数量" },
                            model: {
                              value: _vm.form.qty,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "qty", $$v)
                              },
                              expression: "form.qty",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单价", prop: "unitPrice" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "270px" },
                            attrs: { placeholder: "请输入单价" },
                            model: {
                              value: _vm.form.unitPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "unitPrice", $$v)
                              },
                              expression: "form.unitPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 3, maxRows: 3 },
                              resize: "none",
                              placeholder: "请输入备注内容",
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "footer flex_c_c" }, [
            _c(
              "div",
              { staticClass: "cancel flex_c_c", on: { click: _vm.close } },
              [_vm._v("取消")]
            ),
            _c(
              "div",
              {
                staticClass: "ok flex_c_c",
                on: {
                  click: function ($event) {
                    return _vm.ok("form")
                  },
                },
              },
              [_vm._v("确定")]
            ),
          ]),
        ],
        1
      ),
      _c("BatchAddExpenseDialog", {
        attrs: { dialogVisible: _vm.dialogVisible },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }