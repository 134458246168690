<template>
  <Drawer :drawer="drawer" title="新增费用" :size="600" @close="close">
    <div class="view">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-row>
          <el-col :span="24">
            <el-form-item label="选择类型" prop="classification">
              <el-radio-group v-model="form.classification" @input="onInput">
                <el-radio :label="0">订单费用</el-radio>
                <el-radio :label="1">提单费用</el-radio>
                <el-radio :label="2">充值费用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="费用项" prop="feeNameId">
              <el-select
                v-model="form.feeNameId"
                placeholder="请输入内容"
                style="width: 100%"
                filterable
              >
                <el-option
                  v-for="a in feeOptions"
                  :key="a.value"
                  :label="a.label"
                  :value="a.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              prop="referenceNumber"
              :rules="[
                { required: form.classification !== 2, validator: validateNumber, trigger: 'blur' }
              ]"
            >
              <span
                slot="label"
                class="label"
              >单号<span
                v-if="!form.classification"
              >(订单单号时效需为90天内的)</span
              ><span v-else-if="form.classification === 1">(输入提单/柜号；如有多条分单必须选中一条)</span>
              </span>
              <el-tooltip
                v-model="showTip"
                popper-class="fee-tooltip"
                :manual="true"
                effect="dark"
                placement="bottom-end"
              >
                <el-input
                  v-model="form.referenceNumber"
                  style="width: 100%"
                  placeholder="请输入"
                ></el-input>
                <template slot="content">
                  <div class="data">
                    <BaseTable
                      height="240"
                      :tableData="tableData"
                      :loading="loading"
                      :isPager="false"
                      @handlerRadio="handlerRadio"
                    ></BaseTable>
                  </div>
                  <div class="footer flex_c_c">
                    <div class="cancel flex_c_c" @click="showTip = false">取消</div>
                    <div class="confirm flex_c_c" @click="confirm">确定</div>
                  </div>
                </template>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户" :prop="form.classification === 2 ? 'companyId' : 'companyName'">
              <el-input
                v-if="form.classification !== 2"
                v-model="form.companyName"
                style="width: 270px"
                disabled
              ></el-input>
              <el-select
                v-else
                v-model="form.companyId"
                style="width: 270px"
                placeholder="请选择客户"
                @change="companyChange"
              >
                <el-option
                  v-for="item in companyDrapdown"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="币制" prop="currency">
              <el-input
                v-model="form.currency"
                style="width: 270px"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="数量" prop="qty">
              <el-input
                v-model="form.qty"
                style="width: 270px"
                placeholder="请输入数量"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单价" prop="unitPrice">
              <el-input
                v-model="form.unitPrice"
                style="width: 270px"
                placeholder="请输入单价"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input
                v-model="form.description"
                type="textarea"
                style="width: 100%"
                :autosize="{ minRows: 3, maxRows: 3 }"
                resize="none"
                placeholder="请输入备注内容"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <div class="import flex_c_c" @click="dialogVisible = true">
          <img src="@/assets/images/order/upload.png" />
          批量导入
        </div> -->
      </el-form>
      <div class="footer flex_c_c">
        <div class="cancel flex_c_c" @click="close">取消</div>
        <div class="ok flex_c_c" @click="ok('form')">确定</div>
      </div>
    </div>
    <BatchAddExpenseDialog
      :dialogVisible="dialogVisible"
      @cancel="dialogVisible = false"
    ></BatchAddExpenseDialog>
  </Drawer>
</template>
<script>
const Drawer = () => import('@/components/drawer/Drawer')
const BaseTable = () => import('@/components/table/BaseTable.vue')
import BatchAddExpenseDialog from './BatchAddExpenseDialog'
import { existsMBL } from '@/api/bill'
import {
  existsTrackingNumber,
  addSurcharge,
  getFeeTypeList,
  GetFeeNameList
} from '@/api/fee'
import { debounce } from '@/utils/common'
export default {
  components: {
    BaseTable,
    Drawer,
    BatchAddExpenseDialog
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    companyDrapdown: {
      type: Array,
      default: () => []
    }
  },
  data() {
    // const validateNumber = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error('请输入单号'))
    //   } else {
    //     this.checkNumber(value, callback)
    //   }
    // }
    return {
      validateNumber: (rule, value, callback) => {
        if (!value && this.form.classification !== 2) {
          callback(new Error('请输入单号'))
        } else if (value && this.form.classification !== 2) {
          this.checkNumber(value, callback)
        } else {
          callback()
        }
      },
      form: {
        feeNameId: '',
        referenceNumber: '',
        classification: 0,
        summaryId: '',
        companyId: '',
        companyName: '',
        qty: '',
        unitPrice: '',
        description: '',
        currency: '',
        isImport: false
      },
      rules: {
        feeNameId: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        classification: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        // referenceNumber: [
        //   { required: true, validator: validateNumber, trigger: 'blur' }
        // ],
        companyName: [
          { required: true, message: '请选择客户', trigger: 'blur' }
        ],
        currency: [{ required: true, message: '请选择币制', trigger: 'blur' }],
        qty: [{ required: true, message: '请输入数量', trigger: 'blur' }],
        unitPrice: [{ required: true, message: '请输入单价', trigger: 'blur' }]
      },
      feeOptions: [],
      feeOrder: {},
      feeBill: {},
      fee: {},
      dialogVisible: false,
      showTip: false,
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'companyName',
            label: '客户',
            width: '117'
          },
          {
            filed: 'blNo',
            label: '主提单号',
            width: '117'
          },
          {
            filed: 'blNo',
            label: '分单号',
            width: '117'
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '117'
          }
        ],
        columns: [],
        showRadio: true
      },
      radioObj: {}
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.GetFeeTypeList()
      }
    }
  },
  methods: {
    async GetFeeTypeList() {
      try {
        const res = await getFeeTypeList()
        if (res.success) {
          const feeOrder = res.data.find((a) => a.classification === 0)
          const feeBill = res.data.find((a) => a.classification === 1)
          const fee = res.data.find((a) => a.classification === 2)
          this.feeOrder = feeOrder
          this.feeBill = feeBill
          this.fee = fee
          this.GetFeeNameList(feeOrder.id)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async GetFeeNameList(id) {
      try {
        const res = await GetFeeNameList()
        if (res.success) {
          const feeOptions = res.data
            .filter((a) => a.feeTypeId === id)
            .map((item) => {
              return {
                label: `${item.code} - ${item.name}`,
                value: item.id
              }
            })
          this.feeOptions = feeOptions
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    onInput(v) {
      this.form = {
        ...this.form,
        feeNameId: '',
        referenceNumber: '',
        companyId: '',
        companyName: '',
        currency: ''
      }
      !v && this.GetFeeNameList(this.feeOrder.id)
      v === 1 && this.GetFeeNameList(this.feeBill.id)
      v === 2 && this.GetFeeNameList(this.fee.id)
    },
    // 验证单号
    checkNumber: debounce(function(value, callback) {
      const param = {
        trackingNumber: this.form.classification === 0 ? value : undefined,
        blNo: this.form.classification ? value : undefined
      }
      if (this.form.classification) {
        this.loading = true
        existsMBL(param).then((res) => {
          if (res.success) {
            if (res.data.length === 1) {
              this.form.companyId = res.data[0].companyId
              this.form.companyName = res.data[0].companyName
              this.form.currency = res.data[0].currency
              this.form.summaryId = res.data[0].mblId
            } else if (res.data.length > 1) {
              this.loading = false
              this.form.classification !== 2 && (this.showTip = true)
              this.tableData.columns = res.data
            }
            callback()
          } else {
            callback(new Error('提单号不存在，请重新输入'))
          }
        })
      } else {
        existsTrackingNumber(param).then((res) => {
          if (res.success) {
            this.form.companyId = res.data.companyId
            this.form.companyName = res.data.companyName
            this.form.currency = res.data.currency
            this.form.summaryId = res.data.orderId
            callback()
          } else {
            callback(new Error('订单号不存在，请重新输入'))
          }
        })
      }
    }, 200),
    handlerRadio(v) {
      this.radioObj = v
    },
    close() {
      this.$emit('close')
      this.$refs['form'].resetFields()
      this.tableData.columns = []
      this.showTip = false
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          try {
            const params = {
              ...this.form,
              summaryId: this.form.classification === 2 ? 0 : this.form.summaryId
            }
            const result = await addSurcharge(params)
            if (result.success) {
              this.$message.success(result.errorMessage)
              this.$refs[formName].resetFields()
              this.tableData.columns = []
              this.$emit('ok')
            } else {
              this.$message.error(result.errorMessage)
            }
          } catch (error) {
            console.error(error)
          }
        } else {
          return false
        }
      })
    },
    confirm() {
      this.form.referenceNumber = this.radioObj.blNo
      this.form.companyId = this.radioObj.companyId
      this.form.companyName = this.radioObj.companyName
      this.form.currency = this.radioObj.currency
      this.form.summaryId = this.radioObj.mblId
      this.showTip = false
    },
    companyChange(v) {
      const currency = this.companyDrapdown.find(a => a.id === v).currency
      this.form.currency = currency
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  margin: 20px auto 0;
  padding: 0 20px 100px;
  box-sizing: border-box;
  overflow-y: auto;
  .el-form {
    .el-col:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
    .label {
      span {
        font-size: 12px;
        color: #fe453d;
      }
    }
  }
  .import {
    width: 120px;
    height: 40px;
    background: #35ba83;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .footer {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 600px;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    .cancel {
      width: 120px;
      height: 40px;
      background: #eeeeee;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-right: 10px;
      cursor: pointer;
    }
    .ok {
      width: 120px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      cursor: pointer;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
<style lang="less">
.fee-tooltip {
  width: 560px;
  // height: 340px;
  background: #FFFFFF !important;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
  border-radius: 6px 6px 6px 6px;
  box-sizing: border-box;
  border: 0;
  // top: 358px !important;
}
.fee-tooltip.is-dark[x-placement^="bottom-end"] .popper__arrow {
  border-bottom-color: transparent !important;
}
.fee-tooltip[x-placement^=bottom-end] .popper__arrow{
  border-bottom-color: #FFFFFF;
}
.fee-tooltip[x-placement^=bottom-end] .popper__arrow::before {
  position: absolute;
  top: 0px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
}
.fee-tooltip[x-placement^=bottom-end] .popper__arrow::after {
  border-bottom-color: #FFFFFF;
}
.fee-tooltip .data {
  width: calc(100% - 20px);
  margin: 0 auto;
}
.fee-tooltip .footer {
  width: 100%;
  height: auto;
  margin-top: 20px;
  & div {
    width: 80px;
    height: 40px;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
  }
  .cancel {
    background: #EEEEEE;
    color: #999999;
    margin-right: 20px;
  }
  .confirm {
    background: #2395F3;
  }
}
</style>
